export const theme = {
  colors: {
    black: "#171717",
    white: "#ffffff",
    grey: "#F9F9F9",
    grey2: "#656565",
    cream: "#FFF6EA",
    blue1: "#CAE2F8",
    blue2: "#EBF5FE",
    blue3: "#ACD7FF",
    green: "#50B171",
    green2: "#C6FFE0",
    purple1: "#EDEDFF",
    purple2: "#B3B2FA",
    purple3: "#BFBFFF",
    purple4: "#9060F4",
    purple5: "#DCDCFF",
    yellow: "#FFD953",
    orange1: "#F09122",
    orange2: "#FF5825",
    orange3: "#FF5110",
    orange4: "#FFA36A",
    // Accessibility and forms
    focus: "#50B171",
    error: "#F09122",
  },
  typography: {
    min: 13,
    max: 18,
    minScreen: 400,
    maxScreen: 1440,
    scale: {
      min: 1.125,
      max: 1.125,
    },
  },
  // https://www.smashingmagazine.com/2016/05/fluid-typography/
  fluidType: (exp) => {
    return `
      font-size: ${
        theme.typography.min * Math.pow(theme.typography.scale.min, exp)
      }px;
      @media screen and (min-width: ${theme.typography.minScreen}px ) {
        font-size: calc( ${
          theme.typography.min * Math.pow(theme.typography.scale.min, exp)
        }px + (${
      theme.typography.max * Math.pow(theme.typography.scale.max, exp)
    } - ${
      theme.typography.min * Math.pow(theme.typography.scale.min, exp)
    })*(100vw - ${theme.typography.minScreen}px)/(${
      theme.typography.maxScreen
    } - ${theme.typography.minScreen}) );
      }
      @media screen and (min-width: ${theme.typography.maxScreen}px ) {
        font-size: ${
          theme.typography.max * Math.pow(theme.typography.scale.max, exp)
        }px;
      }
      `
  },
  breakpoints: {
    sm: "min-width: 576px",
    md: "min-width: 768px",
    lg: "min-width: 992px",
    xl: `min-width: 1200px`,
  },
}
