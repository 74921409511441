import React, {useState} from 'react';
import styled from 'styled-components';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import * as Yup from 'yup';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import {ReactComponent as Arrow} from '../../assets/svg/arrow2.svg';


const Holder = styled.div`
  label {
    display: none;
  }

`;


const Inner = styled.div`
  
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
    margin-top: 0.5rem;
    margin-bottom: 2.5rem;
    max-width: 28rem;

    .email {
      grid-column: span 2;
    }

    input[type="email"] {
      line-height: 4rem;
      padding: 0 3.75rem 0 0.75rem;
      margin: 0;
      width: 100%;
      border-radius: 0.2rem;
    }
    input[type="text"] {
      line-height: 4rem;
      padding: 0 0.75rem;
      margin: 0;
      width: 100%;
      border-radius: 0.2rem;
    }
    .button-holder {
      // background-color: ${props => props.theme.colors.white};
      background-color: transparent;
      position: absolute;
      bottom: 0;
      right: 5px;
      height: 4rem;
      button {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        padding: 0;
        margin-top: 0.75rem;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
`;

const Message = styled.div`
`;

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('* Enter a valid email')
    .required('* Email is required'),
  firstName: Yup.string()
    .required('* Name is required'),
  lastName: Yup.string()
    .required('* Name is required'),
});

function SubscribeForm({showDescription}) {

  const [data, setData] = useState(null);

  if (data) {
    return (
      <Message>
        <p dangerouslySetInnerHTML={{__html: data.msg}} />
      </Message>
    )
  }

  return (
    <Formik
      initialValues={{firstName: '', lastName: '', email: ''}}
      validationSchema={validationSchema}
      onSubmit={(values, {setSubmitting}) => {
        setTimeout(() => {
          setSubmitting(false);
         console.log(values);

          addToMailchimp( values.email, {FNAME: values.firstName, LNAME: values.lastName})
            .then(data => {
              setData(data);
            })

        }, 400);
      }}
    >
      {({isSubmitting}) => (
        <Form>
          <Holder className="subscribe-form">
            <Inner className="form-inner">
              <div className="field-holder">
                <label htmlFor="firstName" className="label">First Name</label>
                <Field className="small" aria-label="firstName" type="text" name="firstName" placeholder="First name" />
                <ErrorMessage name="firstName" component="p" />
              </div>
              <div className="field-holder">
                <label htmlFor="lastName" className="label">Last Name</label>
                <Field className="small" aria-label="lastName" type="text" name="lastName" placeholder="Last name" />
                <ErrorMessage name="lastName" component="p" />
              </div>
              <div className="field-holder email">
                <label htmlFor="email" className="label">Email</label>
                <Field className="small" aria-label="email" type="email" name="email" placeholder="Enter your email" />
                <ErrorMessage name="email" component="p" />
              </div>
              <div className="button-holder">
                <button type="submit" disabled={isSubmitting}><Arrow /></button>
              </div>
            </Inner>
          </Holder>
        </Form>
      )}
    </Formik>
  )
}

export default SubscribeForm;
