import {Link} from "gatsby";
import React, {useState} from "react";
import {ReactComponent as Logo} from '../../assets/svg/logo.svg';
import {ReactComponent as Hamburger} from '../../assets/svg/hamburger.svg';
import styled from "styled-components";
import NavigationHolder from "../organisms/NavigationHolder";

const Holder = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: ${props => props.theme.colors.white};
`;

const Inner = styled.div`
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 4.5rem;
  padding: 0 2rem;
  background-color: ${props => props.theme.colors.white};

  svg {
    width: 10rem;
    height: auto;
    overflow: visible;
    @media ( ${props => props.theme.breakpoints.md} ) {
      width: auto;
    }

    rect {
      opacity: 1;
      transform: none;
      transition: all 0.25s ease-in-out;
    }
  }

  button.nav-is-open {
    svg {
      rect:first-child {
        transform: rotate(45deg) translateY(-14%);
        transform-origin: top left;
      }

      rect:nth-child(2) {
        opacity: 0;
      }

      rect:last-child {
        transform: rotate(-45deg) translateY(14%);
        transform-origin: bottom left;
      }
    }
  }

  .toggle-nav {
    margin-left: 2rem;
  }
`;

const DonateHolder = styled.div`
  margin-left: auto;
  
  .nav-is-open {
    transition: transform 0.25s ease-in-out 0.5s, opacity 0.25s ease-in-out;
    transform: scale(0);
    opacity: 0;
  }
`;

const Donate = styled.a`
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out 0.5s;
  transform: scale(1);
  opacity: 1;
`;



function Header() {
    const [navOpen, setNavOpen] = useState(false);

    return (
        <Holder>
            <NavigationHolder
                open={navOpen}
                closeHandler={() => setNavOpen(false)}/>
            <Inner>
                <Link to="/" onClick={() => setNavOpen(false)}><Logo/></Link>
                <DonateHolder>
                <Donate className={`serif button small displayMobile ${navOpen && 'nav-is-open'}`}
                        href="https://square.link/u/Vd943caW"
                        target="_blank" rel="noopener noreferrer">Donate</Donate>
                </DonateHolder>
                <button className={`toggle-nav icon ${navOpen && 'nav-is-open'}`} onClick={() => setNavOpen(!navOpen)}>
                    <Hamburger/></button>
            </Inner>
        </Holder>
    );
}

export default Header;
