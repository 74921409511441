import React from "react"
import Container from "../atoms/Container"
import styled from "styled-components";

const Holder = styled.footer`
  background-color: ${props => props.theme.colors.white};
  padding: 2rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    padding: 4rem 0;
  }
`;

const Button = styled.button`
  font-family: 'New Spirit', serif;
  border-radius: 6px;
  border: none;
  color: white;
  padding: 0.5rem 3rem;
  text-align: center;
  text-decoration: none;
  font-size: ${props => props.theme.fluidType(4)};
  cursor: pointer;
  display: inline-block;
  width: 100%;

  & > span {
    display: none;
  }

  @media ( ${props => props.theme.breakpoints.lg} ) {

    & > span {
      display: inline;
    }
  }
`;


function DonateButton() {
  return (
    <Container>
      <Holder>
        <a href="https://square.link/u/Vd943caW" target="_blank" rel="noopener noreferrer">
        <Button className="serif">
          <span>Help us make a difference &nbsp; &#8213; &nbsp;</span>Make a donation today
        </Button>
        </a>
      </Holder>
    </Container>
  )
}

export default DonateButton;