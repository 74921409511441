import React from "react"
import {Link} from "gatsby"
import styled from "styled-components";
import PropTypes from "prop-types";
import useLockBodyScroll from "../../hooks/useLockBodyScroll";

const Holder = styled.nav`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;
const Group = styled.ul`
  padding-left: 0;
  padding-bottom: 1rem;
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: start;

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  li {
    margin: 0;
    line-height: 2.5;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-color: ${props => props.theme.colors.orange1};
      transition: transform 0.25s ease-in-out;
      transform: scaleX(0);
      transform-origin: top left;
    }

    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }

    ${(props) => props.theme.fluidType(-1)};

    &:first-child {
      ${(props) => props.theme.fluidType(1)};
      font-family: 'New Spirit', serif;
      font-weight: normal;
    }

    @media ( ${props => props.theme.breakpoints.md} ) {
      line-height: 2;
    }
    @media ( ${props => props.theme.breakpoints.lg} ) {
      margin: 0 0 1rem 0;
      line-height: 1.5;
    }
  }
`;

function Navigation({closeHandler}) {
    useLockBodyScroll()
    
    return (
        <Holder
            onClick={() => closeHandler()}>
            <Group>
                <li><Link to="/why-we-exist">Why we exist</Link></li>
                <li><Link to="/why-we-exist">Our story</Link></li>
            </Group>
            <Group>
                <li><Link to="/what-we-do">What we do</Link></li>
                <li><Link to="/research">Research</Link></li>
                <li><Link to="/education">Education</Link></li>
                <li><Link to="/support">Support</Link></li>
            </Group>
            <Group>
                <li><Link to="/who-we-are" state={{directors: true}}>Who we are</Link></li>
                <li><Link to="/who-we-are" state={{directors: true}}>Board of Directors</Link></li>
                <li><Link to="/who-we-are" state={{research: true}}>Research team</Link></li>
                <li><Link to="/who-we-are" state={{support: true}}>Support team</Link></li>
                <li><Link to="/who-we-are" state={{executive: true}}>Operations</Link></li>
                <li><Link to="/who-we-are" state={{ambassadors: true}}>Ambassadors</Link></li>
            </Group>
            <Group>
                <li><Link to="/get-involved">Get involved</Link></li>
                <li><Link to="/articles">News and Events</Link></li>
            </Group>
            <Group>
                <li><Link to="/patients-and-carers">Patients & carers</Link></li>
                <li><Link to="/stories">Stories</Link></li>
                <li><Link to="/patients-and-carers">Patient Resources</Link></li>
            </Group>
        </Holder>
    )
}

Navigation.propTypes = {
    closeHandler: PropTypes.func.isRequired,
};

export default Navigation
