import React from "react"
import {Link} from "gatsby";
import Container from "../atoms/Container"
import {ReactComponent as Logo} from '../../assets/svg/logo.svg';
import styled from "styled-components";
import SubscribeForm from "../atoms/SubscribeForm";
import {ReactComponent as Facebook} from '../../assets/svg/facebook.svg'
import {ReactComponent as Instagram} from '../../assets/svg/instagram.svg'
import {ReactComponent as YouTube} from '../../assets/svg/youtube.svg'
import {ReactComponent as LinkedIn} from '../../assets/svg/linkedin.svg'

const Holder = styled.footer`
  background-color: ${props => props.theme.colors.cream};
  padding: 2rem 0;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding: 4rem 0;
  }
`;

const Inner = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: dense;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-gap: 2rem 6rem;
  }
`;

const LogoHolder = styled.div`

`;

const LinksGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem 3rem;
  ${props => props.theme.fluidType(1)};

  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-row: span 2;
    grid-gap: 3rem;
    align-self: start;
  }
`;

const Group = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 0.25rem 0;
    line-height: 2;
    ${(props) => props.theme.fluidType(1)};

    @media ( ${props => props.theme.breakpoints.md} ) {
      line-height: 1.6;
      ${(props) => props.theme.fluidType(-1)};

    }

    &:first-child {
      font-weight: bold;
      line-height: 3;

      @media ( ${props => props.theme.breakpoints.md} ) {
        line-height: 2;
        ${(props) => props.theme.fluidType(-1)};
      }
    }
`;

const Extras = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  padding-bottom: 2rem;

  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-column: 1/2;
    max-width: 25rem;
    ${(props) => props.theme.fluidType(-1)}
  }

  > div {
    > :first-child {
      margin-top: 0;
    }

    > :last-child {
      margin-bottom: 0;
    }
  }
`;

const Subscription = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media ( ${props => props.theme.breakpoints.md} ) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

`;

const SocialLinks = styled.div`
  font-weight: bold;
  ${(props) => props.theme.fluidType(2)};

  a {
    margin-right: 1rem;
    svg {
      height: 2rem;
      width: auto;
    }
  }

  @media ( ${props => props.theme.breakpoints.md} ) {
    line-height: 2;
    ${(props) => props.theme.fluidType(-1)};

`;

const Acknowledge = styled.div`
`;

const Privacy = styled.div`
  a {
    margin-right: 1rem;
  }
`;


function Footer() {

    return (

        <>
            <Holder>
                <Container>
                    <Inner>
                        <LogoHolder>
                            <Link to="/"><Logo/></Link>
                        </LogoHolder>

                        <LinksGrid>
                            <Group>
                                <li><Link to="/why-we-exist">Why we exist</Link></li>
                                <li><Link to="/why-we-exist">Our story</Link></li>
                            </Group>
                            <Group>
                                <li><Link to="/what-we-do">What we do</Link></li>
                                <li><Link to="/research">Research</Link></li>
                                <li><Link to="/education">Education</Link></li>
                                <li><Link to="/support">Support</Link></li>
                            </Group>
                            <Group>
                                <li><Link to="/who-we-are" state={{directors: true}}>Who we are</Link></li>
                                <li><Link to="/who-we-are" state={{directors: true}}>Board of Directors</Link></li>
                                <li><Link to="/who-we-are" state={{research: true}}>Research team</Link></li>
                                <li><Link to="/who-we-are" state={{support: true}}>Support team</Link></li>
                                <li><Link to="/who-we-are" state={{executive: true}}>Operations</Link></li>
                                <li><Link to="/who-we-are" state={{ambassadors: true}}>Ambassadors</Link></li>
                            </Group>
                            <Group>
                                <li><Link to="/get-involved">Get involved</Link></li>
                                <li><Link to="/articles">News and Events</Link></li>
                            </Group>
                            <Group>
                                <li><Link to="/patients-and-carers">Patients & carers</Link></li>
                                <li><Link to="/stories">Stories</Link></li>
                                <li><Link to="/patients-and-carers">Patient Resources</Link></li>
                            </Group>
                            <Group>
                                <li><Link to="/contact">Contact us</Link></li>
                                <li>(02) 8425 3369</li>
                                <li><a href="mailto:admin@braincancergroup.com.au" rel="noopener noreferrer" aria-label="Mail">Email Us</a></li>
                            </Group>
                        </LinksGrid>

                        <Extras>
                            <Subscription>
                                <p className="sans">Subscribe to our newsletter</p>
                                <SubscribeForm/>
                            </Subscription>
                            <SocialLinks>
                                <a href="https://www.instagram.com/thebraincancergroup/?hl=en" target="_blank"
                                   rel="noopener noreferrer"><Instagram/></a>
                                <a href="https://www.facebook.com/TheBrainCancerGroup/" target="_blank"
                                   rel="noopener noreferrer"><Facebook/></a>
                                <a href="https://www.youtube.com/channel/UCNNXJ24albYaFWoXrbyBaBA" target="_blank" rel="noopener noreferrer" aria-label="YouTube"><YouTube/></a>
                                <a href="https://www.linkedin.com/company/the-brain-cancer-group" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><LinkedIn/></a>
                            </SocialLinks>
                            <Acknowledge>
                                <p>The Brain Cancer Group is located on the traditional lands
                                    of
                                    the Gadigal
                                    people. We acknowledge the Traditional Custodians of Country throughout Australia
                                    and their
                                    connections
                                    to
                                    land,
                                    sea
                                    and community.</p>
                            </Acknowledge>
                            <Privacy>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                                <Link to="/terms-conditions">Terms & Conditions</Link>
                                <Link to="/disclaimer">Disclaimer</Link>
                            </Privacy>
                        </Extras>
                    </Inner>
                </Container>
            </Holder>
        </>
    );
}

export default Footer;