import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import {CSSTransition} from "react-transition-group";
import Navigation from "../molecules/Navigation";
import Container from "../atoms/Container";
import SubNavigation from "../molecules/SubNavigation";
import {ReactComponent as NfpLogo} from '../../assets/svg/nfp-logo.svg';
import AncLogo from '../../assets/png/anc-logo.png';
import SubscribeForm from "../atoms/SubscribeForm";

const timeout = 1000;

const Holder = styled.div`
  position: absolute;
  z-index: 15;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${props => props.theme.colors.white};
  transform-origin: top left;
  padding: 6rem 0 2rem 0;
  overflow-y: scroll;
  @media ( ${props => props.theme.breakpoints.md} ) {
    padding: 10rem 0 2rem 0;
    overflow-y: unset;
  }

  .subscribe-form {
    input[type="email"] {
      border: 0.5px solid ${props => props.theme.colors.black};
      border-radius: 0.2rem;
    }

    input[type="text"] {
      border: 0.5px solid ${props => props.theme.colors.black};
      border-radius: 0.2rem;
    }

    .button-holder {
      background-color: transparent;
    }
  }

  .serif {
    ${(props) => props.theme.fluidType(1)};
  }

  .container {
    height: 100%;
  }

  &.nav-holder-appear,
  &.nav-holder-enter {
    opacity: 0;

    .inner {
      opacity: 0;
    }
  }

  &.nav-holder-appear-active,
  &.nav-holder-appear-done,
  &.nav-holder-enter-active,
  &.nav-holder-enter-done {
    opacity: 1;
    transition: opacity ${timeout / 2}ms;

    .inner {
      opacity: 1;
      transition: opacity ${timeout / 2}ms ${timeout / 2}ms;
    }
  }

  &.nav-holder-exit {
    opacity: 1;

    .inner {
      opacity: 1;
    }
  }

  &.nav-holder-exit-active {
    opacity: 0;
    transition: opacity ${timeout / 2}ms ${timeout / 2}ms;

    .inner {
      opacity: 0;
      transition: opacity ${timeout / 2}ms;
    }
  }
`;

const Inner = styled.div`
  @media ( ${props => props.theme.breakpoints.md} ) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const Main = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 4rem;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-template-columns: 3fr 1fr 2fr;
  }
`;

const TextHolder = styled.div`
  @media ( ${props => props.theme.breakpoints.md} ) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  @media ( ${props => props.theme.breakpoints.lg} ) {
    grid-column: 3/4;
  }
`;

const DonateHolder = styled.div`
  margin-bottom: 2rem;
  display: block;
  max-width: 25rem;
  @media ( ${props => props.theme.breakpoints.md} ) {
    display: block;
  }

  p {
    margin-top: 0;
  }

  .button {
    width: 100%;
  }
`;

const End = styled.div`
  @media ( ${props => props.theme.breakpoints.md} ) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      text-align: right;
    }
  }

  img {
    width: 4rem;
    height: auto;
  }
`;

const CharityLogo = styled.div`
  display: flex;
  flex-direction: column;

  @media ( ${props => props.theme.breakpoints.md} ) {
  align-items: flex-end;
  justify-content: center;
}
`;


function NavigationHolder({open, closeHandler}) {
    return (
        <CSSTransition
            mountOnEnter
            unmountOnExit
            appear
            in={open}
            timeout={timeout}
            classNames="nav-holder"
        >
            <Holder>
                <Container>
                    <Inner className="inner">
                        <Main>
                            <Navigation closeHandler={closeHandler}/>
                            <TextHolder>
                                <DonateHolder>
                                    <p className="serif">Help us make a difference</p>
                                    <p><a className="button serif large"
                                          onClick={() => closeHandler()}
                                          href="https://square.link/u/Vd943caW"
                                          target="_blank" rel="noopener noreferrer">Donate</a></p>
                                </DonateHolder>
                                <div>
                                    <p className="sans">Subscribe to our newsletter</p>
                                    <SubscribeForm/>
                                </div>
                            </TextHolder>
                        </Main>
                        <End>
                            <SubNavigation closeHandler={closeHandler}/>
                            <CharityLogo>
                                <p className="small">ACNC Registered charity</p>
                                <a href="https://www.acnc.gov.au/charity/charities/9306ee4b-38af-e811-a960-000d3ad24282/profile"target="_blank" rel="noopener noreferrer">
                                  <img src={AncLogo} alt="ACNC Registered charity logo"/>
                                </a>
                            </CharityLogo>
                        </End>
                    </Inner>
                </Container>
            </Holder>
        </CSSTransition>
    )
}

NavigationHolder.propTypes = {
    open: PropTypes.bool.isRequired,
    closeHandler: PropTypes.func.isRequired,
};

export default NavigationHolder;