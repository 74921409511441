import React from "react"
import {Link} from "gatsby"
import styled from "styled-components";
import {ReactComponent as Facebook} from '../../assets/svg/facebook.svg'
import {ReactComponent as Instagram} from '../../assets/svg/instagram.svg'
import {ReactComponent as YouTube} from '../../assets/svg/youtube.svg'
import {ReactComponent as LinkedIn} from '../../assets/svg/linkedin.svg'

const Holder = styled.nav`
`;

const Group = styled.ul`
  padding-left: 0;
  list-style: none;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  @media( ${props => props.theme.breakpoints.md} ) {
    margin: 0;
  }
  
  li {
    ${props => props.theme.fluidType(-1)};
    margin: 0 1rem 0 0;
    position: relative;
    
    svg {
      height: 2rem;
      width: auto;
    }
  }
  
  li:first-child {
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      background-color: ${props => props.theme.colors.orange1};
      transition: transform 0.25s ease-in-out;
      transform: scaleX(0);
      transform-origin: top left;
    }
    &:hover {
      &:after {
        transform: scaleX(1);
      }
    }
  }
`;

function SubNavigation({closeHandler}) {

  return (
    <Holder>
      <Group>
          <li><Link to="/contact" onClick={() => closeHandler()}>Contact us</Link></li>
          <li><a href="https://www.instagram.com/thebraincancergroup/?hl=en" target="_blank" rel="noopener noreferrer" aria-label="Instagram"><Instagram/></a></li>
          <li><a href="https://www.facebook.com/TheBrainCancerGroup/" target="_blank" rel="noopener noreferrer" aria-label="Facebook"><Facebook/></a></li>
          <li><a href="https://www.youtube.com/channel/UCNNXJ24albYaFWoXrbyBaBA" target="_blank" rel="noopener noreferrer" aria-label="YouTube"><YouTube/></a></li>
          <li><a href="https://www.linkedin.com/company/the-brain-cancer-group" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn"><LinkedIn/></a></li>
      </Group>
    </Holder>
  )
}

export default SubNavigation
