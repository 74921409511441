import React from "react";
import PropTypes from "prop-types";
import '../utils/fontface.css';
import Header from "../components/molecules/Header";
import DonateButton from "../components/molecules/DonateButton"
import Footer from "../components/molecules/Footer";
import GlobalStyles from "../components/atoms/GlobalStyles";
import styled, {ThemeProvider} from "styled-components";
import {theme} from "../utils/styling";

const Main = styled.main`
  .slice-type {
    position: absolute;
    top: 1rem;
    right: 1rem;
    text-align: right;
    display: block;
    margin: 0;
    font-size: 10px;
    z-index: 5;
    color: red;
  }
`

function Index({location, children}) {
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Header />
        <Main>{children}</Main>
        {location.pathname !== '/' && location.pathname !== '/subscribe/' && <DonateButton />}
        {location.pathname !== '/subscribe/' && <Footer />}
      </>
    </ThemeProvider>
  );
}

Index.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Index;
