exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-article-list-page-js": () => import("./../../../src/templates/ArticleListPage.js" /* webpackChunkName: "component---src-templates-article-list-page-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/Page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-person-js": () => import("./../../../src/templates/Person.js" /* webpackChunkName: "component---src-templates-person-js" */),
  "component---src-templates-story-js": () => import("./../../../src/templates/Story.js" /* webpackChunkName: "component---src-templates-story-js" */),
  "component---src-templates-story-list-page-js": () => import("./../../../src/templates/StoryListPage.js" /* webpackChunkName: "component---src-templates-story-list-page-js" */)
}

